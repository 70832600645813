import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import './Tariffs.css'
import { formatDate } from '../utils/formatDateTime';
import { formatTimeToMoscow } from '../utils/formatDateTime';
import { Context } from '../context/GlobalContext';

const Tariffs = () => {
    const navigate = useNavigate();
    const [tokensData, setTokensData] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const { user } = useContext(Context);

    useEffect(() => {
        fetchTokensData();
    }, []);

    const fetchTokensData = async () => {

        if (!user || !user.id) {
            console.error('User ID is missing');
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/api/thingy-tokens?userId=${user.id}`);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const data = await response.json();
            setTokensData(data);
        } catch (error) {
            console.error('Error fetching tokens data:', error);
        }
    };

    return (
        <div className='wrapper'>
            <div style={{ height: '50px' }}></div>
            <div className='wrapper1'>
                <div className="tab-block-header" onClick={() => navigate(-1)} style={{ cursor: 'pointer', marginBottom: '20px' }}>
                    <div style={{ lineHeight: '0' }}><img style={{ marginRight: '10px', width: '24px', height: '24px' }} src='/icons/arrow-left.png' /></div>
                    <div>Назад</div>
                </div>
                <h1>Ваш баланс</h1>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '10px' }}><span><img src="/icons/coin.png" style={{ width: '20px', transform: 'translateY(2px)' }} alt="coin" /></span><span style={{ fontSize: '26px', fontWeight: '500' }}>{user.token_amount}</span></div>
                <button className='action-button' style={{ width: '100%' }} onClick={() => window.open(`/tokens?re=tokens&targetId=${user.id}`, '_blank')}>Получить токены</button>
                <div style={{ marginTop: '30px', marginBottom: '20px', fontSize: '22px', fontWeight: '500' }}>История транзакций:</div>
                <div className="tokens-container">
                    {tokensData.map((transaction, index) => (
                        <div className="token-card" key={index}>
                            <div className='token-card-left'>
                                <div style={{fontSize: '18px', fontWeight: '500'}}>{transaction.thingy_name}</div>
                                <p>{transaction.project}</p>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    <p style={{
                                        marginRight: '20px',
                                        marginLeft: '10px',
                                        color: transaction.inout === 'out' ? 'green' : 'red',
                                        fontWeight: '500'
                                    }}>
                                        {transaction.inout === 'out' ? '+' : '-'}{transaction.tokens}</p>
                                </div>
                                <div>
                                    <p style={{ opacity: '0.7', fontWeight: '400', fontSize: '12px' }}>{formatDate(transaction.date_created)}</p>
                                    <p style={{ opacity: '0.7', fontWeight: '400', fontSize: '12px' }}>{formatTimeToMoscow(transaction.time_created)} МСК</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Tariffs;
