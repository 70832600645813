import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Tokens.css';

const Tokens = () => {
    const [error, setError] = useState(null);
    const location = useLocation();
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const queryParams = new URLSearchParams(location.search);
    const user = queryParams.get('targetId');

    const generateTimestamp = () => {
        const initialTimestamp = Date.now();
        const trimmedTimestamp = initialTimestamp.toString().substring(4);
        const timestamp = parseInt(trimmedTimestamp);
        return timestamp;
    };

    const handlePurchase = async (tokens, paymentName, price) => {

        if (!tokens || !price || !user || !paymentName || !price) {
            setError('Отсутствуют необходимые данные');
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/api/pay`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: user, name: paymentName, amount: tokens, sum: price, invoice_id: generateTimestamp() }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();

            if (data.paymentLink) {
                window.location.href = data.paymentLink;
            } else {
                console.error('Error getting payment link', error);
            }

        } catch (error) {
            console.error('Error updating balance:', error);
            setError('Failed to update balance. Please try again.');
        }
    };

    return (
        <div className='wrapper'>
            <div className='wrapper1'>
                <div className='menu-top'>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '10px', marginTop: '20px' }}>
                        <img src="/assets/ai-thingy.png" style={{ height: '50px' }} alt="coin" />
                        <div style={{ color: '#333', textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                            <span style={{ fontWeight: 'bold', fontSize: '26px', lineHeight: '1.2', textAlign: 'left' }}>Штучка</span>
                            <span style={{ fontSize: '20px', lineHeight: '1.2' }}>интеллект</span>
                        </div>
                    </div>
                    <div style={{ color: '#333', marginTop: '10px', fontSize: '14px', lineHeight: '1.4', textAlign: 'center' }}>Полезная штучка, разработанная маркетологами<br />для маркетологов и предпринимателей</div>
                </div>
                <div className="tab-block-header" style={{ justifyContent: 'center' }}>
                    <h1 style={{ marginTop: '20px', marginBottom: '40px', textAlign: 'center' }}>Выберите тариф</h1>
                </div>
                <div className="tariffs-wrapper">
                    <div style={{ border: '1px solid #777', borderRadius: '10px', padding: '20px', marginBottom: '20px' }}>
                        <div style={{ opacity: '0.8', fontSize: '14px' }}>~10 генераций</div>
                        <div style={{ fontSize: '18px', fontWeight: '500', marginBottom: '14px' }}>10 000 токенов</div>
                        <div style={{ fontSize: '18px', fontWeight: '500' }}>490 рублей</div>
                        <div style={{ opacity: '0.8', fontSize: '14px', marginBottom: '14px' }}>Хватит, чтобы стартануть с 1 проектом</div>
                        <button className='action-button'
                            onClick={() => handlePurchase(10000, '10000tokens', 490)}
                        >
                            Выбрать
                        </button>
                    </div>

                    <div style={{ border: '1px solid #777', borderRadius: '10px', padding: '20px', marginBottom: '20px' }}>
                        <div style={{ opacity: '0.8', fontSize: '14px' }}>~100 генераций</div>
                        <div style={{ fontSize: '18px', fontWeight: '500', marginBottom: '14px' }}>100 000 токенов</div>
                        <div style={{ fontSize: '18px', fontWeight: '500' }}>2990 рублей</div>
                        <div style={{ opacity: '0.8', fontSize: '14px', marginBottom: '14px' }}>Хватит на месяц для 1-2 проектов</div>
                        <button className='action-button'
                            onClick={() => handlePurchase(100000, '100000tokens', 2990)}
                        >
                            Выбрать
                        </button>
                    </div>

                    <div style={{ border: '1px solid #777', borderRadius: '10px', padding: '20px', marginBottom: '20px' }}>
                        <div style={{ opacity: '0.8', fontSize: '14px' }}>~500 генераций</div>
                        <div style={{ fontSize: '18px', fontWeight: '500', marginBottom: '14px' }}>500 000 токенов</div>
                        <div style={{ fontSize: '18px', fontWeight: '500' }}>5990 рублей</div>
                        <div style={{ opacity: '0.8', fontSize: '14px', marginBottom: '14px' }}>Для тех, у кого много дел</div>
                        <button className='action-button'
                            onClick={() => handlePurchase(500000, '500000tokens', 5990)}
                        >
                            Выбрать
                        </button>
                    </div>

                    {user === 'fd54a22b-c13b-4e78-a53b-d634df56065b' &&
                        <div style={{ border: '1px solid #777', borderRadius: '10px', padding: '20px', marginBottom: '20px' }}>
                            <div style={{ opacity: '0.8', fontSize: '14px' }}>~1 генерация</div>
                            <div style={{ fontSize: '18px', fontWeight: '500', marginBottom: '14px' }}>1 000 токенов</div>
                            <div style={{ fontSize: '18px', fontWeight: '500' }}>1 рубль</div>
                            <div style={{ opacity: '0.8', fontSize: '14px', marginBottom: '14px' }}>Для тех, у кого много дел</div>
                            <button className='action-button'
                                onClick={() => handlePurchase(1000, '1000tokens', 1)}
                            >
                                Выбрать
                            </button>
                        </div>
                    }
                </div>
                {error && <div className="error-message">{error}</div>}
            </div>
        </div>
    );
};

export default Tokens;
