import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ProjectForm from './pages/CreateProject';
import Home from './pages/Home';
import Library from './pages/Library';
import Assistant from './pages/Assistant';
import Confirmation from './pages/Confirmation';
import AppBar from './components/AppBar';
import Projects from './pages/Projects';
import EditProject from './pages/EditProject';
import Tariffs from './pages/Tariffs';
import Onboarding from './pages/Onboarding';
import { ContextProvider } from './context/GlobalContext';
import ThemedGlobalStyle from './GlobalStyles';
import './App.css';
import UserFetcher from './components/UserFetcher';
import LoadingState from './components/LoadingState';
import Privacy from './pages/legalDocs/Privacy';
import Offer from './pages/legalDocs/Offer';
import Tokens from './pages/Tokens';
import OnboardingSlider from './pages/OnboardingSlider';
import { TelegramThemeProvider } from './context/TelegramThemeContext';

function App() {

    const [loading, setLoading] = useState(true);
    const hideAppBarPaths = ['/', '/tokens', '/onboarding-slider', '/onboarding'];
    const hideUserFetcherPaths = ['/tokens'];
    const hideLoadingStatePaths = ['/tokens'];

    useEffect(() => {
        const themeParams = window.Telegram.WebApp.themeParams;
    
        // Check if the theme is dark mode or light mode
        const isDarkMode = themeParams && themeParams.bg_color && themeParams.bg_color.includes('000000'); // Assuming dark theme has black/dark bg color
    
        // Dynamically set the background color based on theme
        document.body.style.backgroundColor = isDarkMode ? 'white' : themeParams.bg_color || 'white';
      }, []);

    const AppContent = () => {
        const location = useLocation();
        const shouldHideAppBar = hideAppBarPaths.includes(location.pathname);
        const shouldHideUserFetcher = hideUserFetcherPaths.includes(location.pathname);
        const shouldHideLoadingState = hideLoadingStatePaths.includes(location.pathname);


        return (
            <>
                {!shouldHideUserFetcher && <UserFetcher setLoading={setLoading} />}
                {!shouldHideAppBar && <AppBar />}
                {!shouldHideLoadingState && loading ? (
                    <LoadingState />
                ) : (
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/library" element={<Library />} />
                        <Route path="/create-project" element={<ProjectForm />} />
                        <Route path="/onboarding" element={<Onboarding />} />
                        <Route path="/onboarding-slider" element={<OnboardingSlider />} />
                        <Route path="/assistant" element={<Assistant />} />
                        <Route path="/confirmation" element={<Confirmation />} />
                        <Route path="/projects" element={<Projects />} />
                        <Route path="/edit-project" element={<EditProject />} />
                        <Route path="/tariffs" element={<Tariffs />} />
                        <Route path="/privacy" element={<Privacy />} />
                        <Route path="/offer" element={<Offer />} />
                        <Route path="/tokens" element={<Tokens />} />
                        <Route path="*" element={<div>Page not found</div>} />
                        </Routes>
                )}
            </>
        );
    };

    return (
        <TelegramThemeProvider>
        <ThemedGlobalStyle />
        <ContextProvider>
            <Router>
                
                <AppContent />
            </Router>
        </ContextProvider>
        </TelegramThemeProvider>
    );
}

export default App;