import React, { useRef, useState, useContext, useEffect } from 'react';
import { Context } from '../context/GlobalContext';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import './OnboardingSlider.css';

const OnboardingSlider = () => {

    const sliderRef = useRef(null);
    const navigate = useNavigate();
    const [currentSlide, setCurrentSlide] = useState(0);
    const { selectedProject } = useContext(Context);

    useEffect(() => {
        if (selectedProject) {
            console.log('selectedProject')
        } else {
            console.log('no selectedProject')
        }
    }, [selectedProject]);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex),
    };

    const slides = [
        {
            image: "/assets/ai-thingy.png", // Adjust the image paths
            title: "GPT-ассистент с библиотекой промптов для маркетологов",
            description: "Добро пожаловать в Штучку-Интеллект! Этот ИИ-ассистент поможет вам с рутиной, креативом и стратегией, чтобы вы экономили время и сосредоточились на главном",
        },
        {
            image: "/assets/ai-thingy.png",
            title: "Добавьте проект и введите описание",
            description: "Описание вашего проекта автоматически добавляется в каждый промпт, позволяя избежать повторного ввода данных...",
        },
        {
            image: "/assets/ai-thingy.png",
            title: "Решения для любых задач",
            description: "Выберите подходящую категорию в каталоге и найдите промпт, идеально соответствующий вашей задаче...",
        },
        {
            image: "/assets/ai-thingy.png",
            title: "Напиши сценарий для Reels",
            description: "В каждой карточке промпта есть примеры и вопросы, которые помогут вам предоставить дополнительную информацию...",
        },
        {
            image: "/assets/ai-thingy.png",
            title: "Получайте готовые решения одним кликом",
            description: "После того как вы ввели всю необходимую информацию, просто нажмите кнопку «Сгенерировать»...",
        },
    ];

    const nextSlide = () => {
        sliderRef.current.slickNext();
    };

    const handleSkip = () => {
        if (selectedProject)    {
            navigate('/library');
        } else {
            navigate('/onboarding');
        }
    };

    return (
        <div className="onboarding-slider">
            <Slider ref={sliderRef} {...settings}>
                {slides.map((slide, index) => (
                    <div key={index} className="slide">
                        <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px'}}><img src={slide.image} alt={slide.title} className="slide-image" /></div>
                        <h2 className="slide-title">{slide.title}</h2>
                        <p className="slide-description">{slide.description}</p>
                    </div>
                ))}
            </Slider>
            <div className="fixed-buttons">
                {currentSlide < slides.length - 1 ? (
                    <>
                        <button className="skip-button" onClick={handleSkip}>Пропустить</button>
                        <button className="next-button" onClick={nextSlide}>Далее</button>
                    </>
                ) : (
                    <button className="start-button" onClick={handleSkip}>Начать пользоваться</button>
                )}
            </div>
        </div>
    );
};

export default OnboardingSlider;
